import axios from 'axios';
import { doc, getDoc } from "firebase/firestore";
import { db } from "./App";

export const fetchRandomWords = async () => {
    try {
        const response = await axios.get(
            `https://api.datamuse.com/words?rel_jja=beautiful&max=1000`
        );
        const words = response.data.map((item) => item.word);
        if (words.length < 2) {
            return ['error', 'error'];
        }

        // Derive deterministic indices from hash
        const startIndex = Math.floor(Math.random() * words.length);
        let targetIndex = Math.floor(Math.random() * words.length);
        
        // Ensure the target word is different from the start word
        if(targetIndex === startIndex)
        {
            targetIndex = (targetIndex + 1) % words.length
        }
        
        if(words[targetIndex].includes(words[startIndex]))
        {
            console.error("Target word contains start word. Fetching new target word.")
            targetIndex = (targetIndex + 1) % words.length
        }

        // Step 4: Fetch related words for the start word
        const relatedWords = await fetchRelatedWords(words[startIndex]);

        // Step 5: Ensure the target word is not in the related words
        while (relatedWords.includes(words[targetIndex])) {
            console.error("Target word too closely related to start word. Fetching new target word.")
            targetIndex = (targetIndex + 1) % words.length

        }
        
        return [words[startIndex], words[targetIndex]];
    } catch (error) {
        console.error('Error fetching word of the day:', error);
        return ['error', 'error'];
    }
};

export const fetchRelatedWords = async (word) => {
    const urls = [
        `https://api.datamuse.com/words?ml=${word}&max=1000`,
        `https://api.datamuse.com/words?rel_trg=${word}&max=1000`,
        `https://api.datamuse.com/words?rel_syn=${word}&max=1000`,
    ];

    try {
        const responses = await Promise.all(urls.map((url) => axios.get(url)));
        const allWords = responses.flatMap((response) =>
            response.data.map((item) => item.word)
        );
        return Array.from(new Set(allWords));
        
        //setHints(generateHints(responses[0].data, currentWord, path));
    } catch (err) {
        console.error('Error fetching related words:', err);
        return null;
    }
};

export const fetchWordOfTheDay = async () => {
    try {
        const docRef = doc(db, "wordOfTheDay", "current");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            console.log("Word of the Day:", data);
            return [data.startWord, data.targetWord];
        } else {
            console.error("No Word of the Day found.");
            return null;
        }
    } catch (error) {
        console.error("Error fetching Word of the Day:", error);
        return null;
    }
};