import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Game from './Game';
import BackButton from "./components/BackButton";
import CreditFooter from "./components/CreditFooter";

const GamePage = () => {
    const { startWord, targetWord } = useParams();
    const navigate = useNavigate();

    return (
        <div
            className="min-h-screen flex flex-col items-center p-4 text-white bg-gradient-to-b from-blue-500 to-purple-700">
            {/*<h1 className="text-4xl font-bold mb-8">Chain Words</h1>*/}
            <img
                src="/WordHopLogo.png"
                alt="Word Hop Logo"
                className="w-96 h-auto mb-8"
                onClick={() => navigate('/')}

            />
            <div className="bg-white text-black rounded-lg shadow-lg w-full max-w-md p-6">
                <Game startWord={startWord} targetWord={targetWord}/>
            </div>
            <BackButton/>
            {/*
            <BannerAd />
*/}
            <CreditFooter />
        </div>
    );
};

export default GamePage;