import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchRandomWords, fetchWordOfTheDay } from './api';
import { FaQuestionCircle, FaArrowRight } from 'react-icons/fa';
import HowToPlayModal from "./components/HowToPlayModal";
import CreditFooter from "./components/CreditFooter";

const MainPage = () => {
    const [dailyWords, setDailyWords] = useState({ start: '', target: '' });
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const loadDailyWords = async () => {
            const [startWord, targetWord] = await fetchWordOfTheDay();
            setDailyWords({ start: startWord, target: targetWord });
        };
        loadDailyWords();
        const hasSeenModal = localStorage.getItem('hasSeenHowToPlay');
        if (!hasSeenModal) {
            setIsModalOpen(true);
            localStorage.setItem('hasSeenHowToPlay', 'true'); // Mark as seen
        }
    }, []);

    return (
        <div className="min-h-screen flex flex-col items-center text-center p-6 text-white bg-gradient-to-b from-blue-500 to-purple-700">
            <img
                src="/WordHopLogo.png"
                alt="Word Hop Logo"
                className="w-96 h-auto mb-8"
            />
            {/* Main Game Area */}
            <div
                className="flex relative items-center justify-between w-full max-w-md bg-white text-black rounded-lg shadow-lg pt-8 pb-8 space-x-4">
                <button
                    className="absolute -top-4 -right-4 bg-gray-800 text-white font-bold p-3 w-8 h-8 rounded-full text-lg flex items-center justify-center shadow-md hover:bg-gray-700"
                    onClick={() => setIsModalOpen(true)}
                    title="How to Play"
                >
                    i
                </button>

                {/* Start Word */}
                <div className="text-center flex-1">
                    <p className="font-extrabold text-[clamp(1.5rem, 5vw, 3rem)] break-words">
                        {dailyWords.start || 'Loading...'}
                    </p>
                </div>

                {/* Larger Arrow and Question Mark Section */}
                <div className="flex items-center justify-center space-x-8 flex-shrink-0">
                    <FaArrowRight className="text-3xl text-gray-400"/>
                    <FaQuestionCircle className="text-5xl text-blue-500 animate-pulse"/>
                    <FaArrowRight className="text-3xl text-gray-400"/></div>


                {/* Target Word */}
                <div className="text-center flex-1">
                    <p className="font-extrabold text-[clamp(1.5rem, 5vw, 3rem)] break-words">
                        {dailyWords.target || 'Loading...'}
                    </p>
                </div>
            </div>

            {/* Buttons */}
            <div className="mt-8 space-y-4 w-full max-w-md">
                <button
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-6 rounded w-full"
                    onClick={() => navigate(`/game/${dailyWords.start}/${dailyWords.target}`)}
                >
                    Solve
                </button>
                <button
                    onClick={async () => {
                        const [startWord, targetWord] = await fetchRandomWords();
                        window.location.href = `/game/${startWord}/${targetWord}`;
                    }}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded w-full"
                >
                    Random
                </button>
                <button
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-3 px-6 rounded w-full"
                    onClick={() => navigate('/custom')}
                >
                    Create Your Own
                </button>
            </div>
            <HowToPlayModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
            <CreditFooter />
        </div>
    );
};

export default MainPage;
