import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {fetchRandomWords, fetchRelatedWords} from './api';
import HowToPlayModal from "./components/HowToPlayModal";

const Game = ({ startWord, targetWord }) => {
    const [currentWord, setCurrentWord] = useState(startWord);
    const [path, setPath] = useState([startWord]);
    const [displayedPath, setDisplayedPath] = useState([startWord]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [inputWord, setInputWord] = useState('');
    const [relatedWords, setRelatedWords] = useState([]);
    const [autocompleteWord, setAutocompleteWord] = useState('');
    const [hints, setHints] = useState([]);
    const [error, setError] = useState('');
    const [incorrectCount, setIncorrectCount] = useState(0);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            let uniqueWords = await fetchRelatedWords(currentWord);
            
            setRelatedWords(uniqueWords);

            if (uniqueWords.includes(targetWord.toLowerCase())) {
                setDisplayedPath((prev) => [...prev, '→', targetWord]);
                setPath((prev) => [...prev, targetWord]);
                setSuccess(true);
            }
            };
        fetchData();
        
        
    }, [currentWord]);

    const generateHints = (apiResponse, currentWord, previousAttempts) => {
        const filteredWords = apiResponse.filter((item) => {
            const word = item.word.toLowerCase();
            return (
                word !== currentWord.toLowerCase() &&
                !previousAttempts.includes(word) &&
                /^[a-zA-Z]+$/.test(word) &&
                word.length > 2
            );
        });

        const sortedWords = filteredWords.sort((a, b) => b.score - a.score);
        return sortedWords.slice(0, 5).map((item) => item.word);
    };
    
    const generateShareMessage = (linkCount) => {
        const chainLinks = "🔗".repeat(linkCount);
        return `WordHop io ${chainLinks}\n(Solved in ${linkCount} ${linkCount > 1 ? 'links' : 'link'}! Can you beat my score?)\n\n${window.location.origin}/game/${startWord}/${targetWord}`;
    };
    
    const handleInputChange = (e) => {
        const value = e.target.value.toLowerCase();
        setInputWord(value);

        if(currentWord.toLowerCase().includes(value))
        {
            setAutocompleteWord('');
        }
        else
        {
            // Find the first word that starts with the input value
            const match = relatedWords.find((word) => word.startsWith(value));

            setAutocompleteWord(match || '');
            setError(!match && !currentWord.toLowerCase().includes(inputWord.trim().toLowerCase()) &&  value ? 'Invalid word' : '');

        }
   };

    const handleInput = () => {
        const trimmedInput = inputWord.trim().toLowerCase();

        if (
            !relatedWords.includes(trimmedInput) &&
            !currentWord.toLowerCase().includes(trimmedInput)
        ) {
            setError(`'${inputWord.trim()}' is not a valid related word.`);
            setIncorrectCount((prev) => prev + 1);

            if (incorrectCount + 1 >= 2) {
                setError(`Here are some hints for '${currentWord}': ${hints.join(', ')}`);
                setIncorrectCount(0);
            }

            setInputWord('');
            setAutocompleteWord('');
            return;
        }

        setDisplayedPath((prev) => [...prev, '→', inputWord.trim()]);
        setPath((prev) => [...prev, inputWord.trim()]);
        setCurrentWord(inputWord.trim());
        setInputWord('');
        setAutocompleteWord('');
        setError('');
        setIncorrectCount(0);

        if (trimmedInput === targetWord.toLowerCase()) {
            setSuccess(true);
        }
    };

    return (
        <div className="text-center">

            <div className="flex flex-wrap items-start w-full max-w-md pb-8 ps-2 pt-4 space-x-4 relative">
                <button
                    className="absolute -top-10 -right-10 bg-gray-800 text-white font-bold w-8 h-8 rounded-full text-lg flex items-center justify-center shadow-md hover:bg-gray-700"
                    onClick={() => setIsModalOpen(true)}
                    title="How to Play"
                >
                    i
                </button>
                {displayedPath.map((item, index) => (
                    <div key={index} className="text-center">
                        <p className={`font-extrabold text-[clamp(1.5rem, 5vw, 3rem)] break-words ${index > 0 && (!success || index < displayedPath.length - 1) && item !== '→' ? 'text-green-500' : ''}`}>
                            {item}
                        </p>
                    </div>
                ))}

                {!success && (
                    <>
                        <div className="text-center">
                            <p className="font-extrabold text-[clamp(1.5rem, 5vw, 3rem)] break-words">
                                →
                            </p>
                        </div>
                        <div className="text-center">
                            <p className="font-extrabold text-[clamp(1.5rem, 5vw, 3rem)] break-words">
                                ?
                            </p>
                        </div>
                        <div className="text-center">
                            <p className="font-extrabold text-[clamp(1.5rem, 5vw, 3rem)] break-words">
                                →
                            </p>
                        </div>
                        <div className="text-center">
                            <p className="font-extrabold text-[clamp(1.5rem, 5vw, 3rem)] break-words">
                                {targetWord}
                            </p>
                        </div>
                    </>
                )}
            </div>

            {success ? (
                <div>
                    <p className="text-green-600 font-bold text-xl mb-4">
                        🎉 Congratulations! You reached the target word
                        in {path.length - 2} {path.length > 3 ? 'links' : 'link'}! 🎉
                    </p>
                    <div className="mb-4">
                        <button
                            onClick={() => {
                                const shareMessage = generateShareMessage(path.length - 2);
                                navigator.clipboard.writeText(shareMessage);
                                alert('Game link copied to clipboard!');
                            }}
                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded w-full"
                        >
                            Share
                        </button>
                    </div>
                    <div className="mb-4">
                        <button
                            onClick={async () => {
                                const [startWord, targetWord] = await fetchRandomWords();
                                window.location.href = `/game/${startWord}/${targetWord}`;
                            }}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mb-4"
                        >
                            Start New Random Game
                        </button>
                    </div>
                </div>
            ) : (
                <>
                    <div className="mb-4 relative">
                        {/* Autocomplete Word */}
                        {inputWord !== '' && autocompleteWord && (
                            <div
                                className="absolute inset-0 flex items-center text-gray-400 pointer-events-none"
                                style={{
                                    fontFamily: 'inherit',
                                    fontSize: 'inherit',
                                    lineHeight: 'inherit',
                                    paddingLeft: '0.55rem', // Match input's padding
                                }}
                            >
                                {autocompleteWord}
                            </div>
                        )}

                        {/* Input Field */}
                        <input
                            type="text"
                            value={inputWord}
                            onChange={handleInputChange}
                            onKeyDown={(e) => e.key === 'Enter' && handleInput()}
                            className={`w-full p-2 border rounded ${
                                error ? 'border-red-500' : 'border-gray-300'
                            }`}
                            placeholder="Enter a related word"
                            style={{
                                background: 'transparent',
                                position: 'relative',
                                zIndex: 1,
                            }}
                        />
                    </div>

                    <button
                        onClick={handleInput}
                        className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full ${
                            error || !inputWord ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        disabled={!!error || !inputWord}
                    >
                        Submit
                    </button>
                </>
            )}
            <HowToPlayModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}/>

            {error && <p className="text-red-600 mt-4">{error}</p>}
        </div>
    );
};

export default Game;
