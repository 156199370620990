import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './MainPage';
import GamePage from './GamePage';
import CustomPage from './CustomPage';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDyRf_LBT4MidLBBu37QAtih6UmyGtA4HA",
    authDomain: "word-hop-ef192.firebaseapp.com",
    projectId: "word-hop-ef192",
    storageBucket: "word-hop-ef192.firebasestorage.app",
    messagingSenderId: "248674240578",
    appId: "1:248674240578:web:a3df7970def919d1c485db",
    measurementId: "G-MPFX3S6LPF"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);

const App = () => (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/game/:startWord/:targetWord" element={<GamePage />} />
        <Route path="/custom" element={<CustomPage />} />
      </Routes>
    </Router>
);

export default App;

