import React from 'react';

const HowToPlayModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-11/12 max-w-lg shadow-lg text-black">
                <h2 className="text-2xl font-bold mb-4">How to Play</h2>
                <p className="mb-4">
                    The goal is to connect the starting word to the target word in as few links as possible. Each word must be related to the previous one by meaning, context, or association.
                </p>
                <p className="mb-4">
                    You can also use parts of the current word, like shorter forms or related variations. For example:
                </p>
                <p className="bg-gray-100 p-4 rounded mb-4">
                    Starting word: <strong>sunlight</strong> → Try <strong>light</strong> or <strong>sun</strong>.
                </p>
                <h3 className="text-xl font-semibold mb-2">Example:</h3>
                <div className="bg-gray-100 p-4 rounded mb-4">
                    <p><strong>Start:</strong> Jewel</p>
                    <p><strong>Link 1:</strong> Brilliant</p>
                    <p><strong>Link 2:</strong> Bright</p>
                    <p><strong>Link 3:</strong> Sunny</p>
                    <p><strong>Target:</strong> Summer</p>
                    <p className="mt-2"><strong>Result:</strong> Reached the target word in just 3 links!</p>
                </div>
                <p className="mb-4">Try to reach the target word with the fewest links for a high score!</p>
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={onClose}
                >
                    Got It!
                </button>
            </div>
        </div>
    );
};

export default HowToPlayModal;
