// src/components/Footer.js

import React from 'react';

function CreditFooter() {
    return (
        <footer style={footerStyle}>
            <p style={paragraphStyle}>
                Data provided by{' '}
                <a
                    href="https://www.datamuse.com/api/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={linkStyle}
                >
                    Datamuse API
                </a>
                .
            </p>
        </footer>
    );
}

const footerStyle = {
    textAlign: 'center',
    padding: '0.5em 0',
    fontSize: '0.8em',
    position: 'fixed',
    bottom: 0,
    // Removed backgroundColor and position properties
};

const paragraphStyle = {
    margin: 0,
};

const linkStyle = {
    color: 'inherit',
    textDecoration: 'underline',
};

export default CreditFooter;
