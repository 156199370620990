import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackButton from "./components/BackButton";
import CreditFooter from "./components/CreditFooter";

const CustomPage = () => {
    const [startWord, setStartWord] = useState('');
    const [targetWord, setTargetWord] = useState('');
    const navigate = useNavigate();

    const handleCreateGame = () => {
        if (!startWord || !targetWord) return alert('Both words are required!');
        navigate(`/game/${startWord.trim().toLowerCase()}/${targetWord.trim().toLowerCase()}`);
    };

    return (
            <div
                className="min-h-screen flex flex-col items-center text-white bg-gradient-to-b from-blue-500 to-purple-700 p-6">
                <img
                    src="/WordHopLogo.png"
                    alt="Word Hop Logo"
                    className="w-96 h-auto mb-8"
                    onClick={() => navigate('/')}
                />
                <div className="bg-white text-black p-6 rounded-lg shadow-md w-80">
                    <div className="mb-4">
                        <label className="block font-bold mb-2">Start Word</label>
                        <input
                            type="text"
                            value={startWord}
                            onChange={(e) => setStartWord(e.target.value)}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block font-bold mb-2">Target Word</label>
                        <input
                            type="text"
                            value={targetWord}
                            onChange={(e) => setTargetWord(e.target.value)}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <button
                        onClick={handleCreateGame}
                        className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Create Game
                    </button>
                </div>
                <BackButton/>
                <CreditFooter />

            </div>
            );
            };

            export default CustomPage;
